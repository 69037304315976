$mq-breakpoints: (mobile: 320px,
    tablet: 768px,
    desktop: 992px,
    wide: 1200px);


/////////////////////////////////////:
// colors

$bg-color: #F2F2F2;
$dark-gray: #4A4A4A;
$table-header: #535353;
$table-odd-row: #F9F8F8;
$table-even-row: #FFF;
