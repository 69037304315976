/* You can add global styles to this file, and also import other style files */

@import 'app/scss/variables';
@import '~sass-mq/_mq';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
body {
    margin: 0;
    padding: 0;
}

.main-content {
    padding: 20px 20px 0 20px;
}

h1 {
    padding: 0 !important;
    margin-top: 0.8rem !important;
    font-size: 2.5rem !important;
    line-height: 2.625rem !important;
    letter-spacing: 1px;
    color: var(--total-red-600);
    font-family: var(--total-font-nunito-light);
    font-weight: bold;
    display: inline-block;
}

h2 {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
    letter-spacing: 1px;
    color: var(--total-gray-800) !important;
    font-family: var(--total-font-nunito-light) !important;
    padding-bottom: 0 !important;
}

h3 {
    font-size: 1.125rem !important;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
    color: var(--total-blue-800) !important;
    font-family: var(--total-font-roboto-medium) !important;
}

p {
    font-family: var(--total-font-roboto-regular);
    font-size: 0.875rem !important;
    line-height: 1.5rem;
    &.emphasis {
        margin: 1rem 0 .5rem 0;
        line-height: 1.5rem;
    }
}

.emphasis {
    font-size: .875rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: var(--total-blue-800);
}

a {
    color: var(--total-gray-800) !important;
    text-decoration: underline;
    font-size: 0.875rem;
    line-height: 1rem;
}

label {
    color: $dark-gray;
}

hr {
    border-top: 2px solid var(--total-gray-800);
    margin: 1.25rem 0 1rem;
    &.light {
        border-color: var(--total-gray-400);
    }
}

.color-red-600 {
    color: var(--total-red-600) !important;
}

mat-icon.red {
    filter: invert(22%) sepia(97%) saturate(6590%) hue-rotate(342deg) brightness(88%) contrast(99%);
}

mat-icon.white {
    filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(167deg) brightness(120%) contrast(97%);
}

mat-icon.gray {
    filter: invert(65%) sepia(11%) saturate(7%) hue-rotate(347deg) brightness(93%) contrast(87%);
}

.vertical-divider {
    height: 20px;
    width: 2px;
    background-color: #acacac;
    border-right: 1px solid var(--total-gray-400);
}


/***** Material angular CSS override *****
 *****************************************/

.mat-card {
    padding: 20px !important;
    box-shadow: none !important;
}

.mat-card,
.mat-dialog-container,
.mat-button-toggle-group,
.mat-expansion-panel {
    border-radius: 0 !important;
}

button:not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) {
    border-radius: 110px;
    border: 1px solid transparent;
    &.mat-stroked-button:not([disabled]) {
        border-color: var(--total-red-600);
    }
    &:hover {
        color: #ED0000 !important;
        border: 1px solid #ED0000;
        background-color: #ffffff;
    }
}

.mat-hint,
.mat-error {
    font-size: .75rem;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, .54) !important;
}

.mat-table {
    .mat-header-row {
        font-size: .625rem;
        line-height: 1rem;
        font-family: var(--total-font-helvetica-bdcn);
        color: #535353;
        text-transform: uppercase;
    }
    .mat-row:nth-child(2n) {
        background-color: #FFF;
    }
    .mat-row:nth-child(2n+1) {
        background-color: var(--total-gray-400);
    }
    tr th:not(:first-of-type):not(:last-of-type),
    tr td:not(:first-of-type):not(:last-of-type) {
        padding: 0 .5rem !important;
    }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 10px 20px 10px 10px !important;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: var(--total-font-roboto-medium);
}

.mat-hint {
    font-family: var(--total-font-roboto-medium);
    font-size: .75rem;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0 !important;
}

.custom-dialog-container .mat-dialog-container {
    padding: 0;
    padding-bottom: 24px;
    min-width: 420px;
    overflow-x: hidden;
    .mat-dialog-title {
        background: var(--total-red-600);
    }
    h1 {
        font-family: var(--total-font-roboto-medium) !important;
        font-size: 25px !important;
        color: #FFF !important;
    }
    .close-button {
        color: #FFF;
    }
}


/***** App-specific global CSS *****
 ***********************************/

.button-link {
    display: inline-flex;
    line-height: 2.125rem;
    color: var(--total-gray-800) !important;
    .mat-icon {
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
    }
    &.back-button {
        margin-top: 0.625rem;
        .mat-icon {
            margin-right: 0.5rem;
            opacity: 0.5;
        }
    }
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(0, 0, 0, .18) !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 0 !important;
}


/***** Hacks *****
 *****************/


/**
 * Hiding spinners for <input type="number">
 */


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}
